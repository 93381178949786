<template>
  <div class="big">
    <ul class="header">
      <li style="margin-right:20px;"><img class="albumimage" src="../../../assets/jobbg.png" alt=""/></li>
      <li>
        <div style="margin-bottom:10px;"><span class="albumname">{{ obj.name }}</span><img class="play" @click="play()"
                                                                                           :title="titleWords"
                                                                                           :src="playerImg" alt="">
        </div>
        <!-- 账号名称和头像 -->
        <div><img class="headportrait" :src="$store.state.user.avatar" alt=""><span style="font-size:13px;"
                                                                                    class="name">{{ $store.state.user.name }}</span>
        </div>
      </li>
    </ul>
    <ul class="title-nav">
      <li><span class="start">作词</span><span :title="obj.lyricist"
                                             class="content nameContent ">{{ obj.lyricist }}</span></li>
      <li><span class="start">作曲</span><span :title="obj.composer" class="content nameContent">{{ obj.composer }}</span>
      </li>
      <li><span class="start">提交时间</span><span class="content">{{ $timestampToString(obj.submitTime) }}</span></li>
    </ul>
    <div class="remarks">
      <span class="start">备注说明</span>
      <div :class="{'text-active': switchflag }" class="text">
        <div v-if="obj.remarks !=undefined&&obj.remarks.length>=65" class="but" :class="switchflag?'butactive':''"
             @click="switchflag=!switchflag">{{ text }}<img class="imgsrc" :src="textimg"></div>
        {{ obj.remarks }}
      </div>
    </div>
    <div>
      <audio ref="audio">
        <source :src="obj.songPath" type="audio/mp3"/>
        <source :src="obj.songPath" type="audio/asf"/>
        <source :src="obj.songPath" type="audio/wma"/>
        <source :src="obj.songPath" type="audio/wav"/>
        <source :src="obj.songPath" type="audio/m4a"/>
        Your browser does not support this audio format.
      </audio>
    </div>
  </div>
</template>

<script>
import down from '../../../assets/down.png'
import up from '../../../assets/up.png'
import Play from '../../../assets/play.png'
import Suspend from '../../../assets/suspend.png'

export default {
  props: ['obj'],
  data() {
    return {
      switchflag: true,
      text: '展开',
      textimg: down,
      playerImg: Play,
      titleWords: '播放',
      playfalg: true,
    }
  },
  methods: {
    play() {
      this.$nextTick(() => {
        if (this.$refs.audio.paused) {
          //点击第一次播放时让歌曲重新加载，避免歌曲播放不了
          if (this.playfalg) {
            this.$refs.audio.load();
            this.playfalg = false;
          }
          this.$refs.audio.play().then(res => {
            if (res !== undefined) {

              this.$refs.audio.play()
            }
          })
          this.titleWords = '暂停'
          this.playerImg = Suspend
        } else {
          this.$refs.audio.pause();
          this.titleWords = '播放'
          this.playerImg = Play
        }
      });
    },
  },
  watch: {
    obj: {
      deep: true,
      handler(newV) {
        this.$nextTick(
            () => {
              this.obj = newV
            }
        );
      }
    },
    switchflag(newval) {
      if (newval) {
        this.text = '展开'
        this.textimg = down
      } else {
        this.text = '收起'
        this.textimg = up
      }
    }
  }
}
</script>

<style scoped lang="scss">
* {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.big {
  // display: inline-block;
  .header {
    display: flex;

    .headportrait {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 12px;
    }

    .albumname {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      // vertical-align: middle;
    }

    .play {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-left: 12px;
      cursor: pointer;
    }

    .albumimage {
      width: 60px;
      height: 60px;
    }
  }

  .title-nav {
    display: flex;

    > li {
      margin-top: 16px;
      margin-right: 27px;
      white-space: nowrap;
    }

    .content {
      color: #666666;
      font-size: 13px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
    }

    .nameContent {
      display: inline-block;
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }

  }

  .start {
    margin-right: 10px;
    font-size: 13px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #999999;
    white-space: nowrap;
    width: 60px;
  }

  .text {
    font-size: 13px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #666666;
    width: 455px;
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  .text-active {
    transition: height 1s;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .but {
    font-size: 13px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: right;
    color: #ff004d;
    background-color: #fff;
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;

    > .imgsrc {
      width: 12px;
      height: 12px;
    }

  }

  .butactive {
    width: 60px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1) 30%);
  }

  .remarks {
    margin-top: 12px;
    display: flex;
    box-sizing: border-box;
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;

  }
}
</style>
