<template>
  <div class="works">
    <LeftMenu current="taskrun" />
    <div class="main">
      <el-row>
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/taskrun">已上交</el-breadcrumb-item>
            <el-breadcrumb-item>作品详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"
          ><el-link type="primary" :underline="false" @click="$router.back()">
            返回</el-link
          ></el-col
        >
      </el-row>
      <el-divider class="border-bottom"></el-divider>
      <p class="titleName">作品详情</p>
      <el-row :gutter="78">
        <el-col :span="15">
          <HeaderDetails :obj="obj" />
          <Comment :comment="comment" />
          <div class="footer"><el-button  @click="$router.back()" >返回</el-button></div>
        </el-col>
        <el-col :span="7">
          <p class="lyric-title">歌词</p>
          <div class="lyric" >
            <pre style="margin: 0; white-space:pre-line">{{lyriccontent}}</pre>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import LeftMenu from '../../components/LeftMenu.vue'
import HeaderDetails from './components/HeaderDetails.vue'
import Comment from './components/Comment.vue'
import {train_taskSongDetail} from '../../api/train'
export default {
  components: {
    LeftMenu,
    HeaderDetails,
    Comment
  },
  created(){
      this.submitWork()
  },
  data() {
      return {
          obj:'',
          lyriccontent:'',
          comment:{}
      };
  },
  methods:{
   submitWork(){
       train_taskSongDetail(this.$route.query.id).then(res => {
            this.obj=res.data.trainStuSongs
            let {commentNum,favNum}=res.data.trainStuSongs
            this.comment={
              commentNum,favNum
            }
            this.lyric()

       })
   },
   lyric(){
       this.$get(this.obj.lrcPath).then(res => {
           this.lyriccontent=res||'暂无歌曲'
       })
   }
  },
// 返回首页显示已上交表格
  beforeRouteLeave(to, from, next){
      if(to.name==='Taskrun'){
       to.params.submitState=1
      }
      next()
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/initialization.scss";
@import "../../styles/works.scss";
.main{
    min-height: 950px
}
.border-bottom {
  margin-top: 12px;
  margin-bottom: 30px;
}
.titleName {
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #333333;
  margin-bottom: 20px;
}
.footer {
  text-align: center;
  .el-button {
    width: 160px;
    height: 50px;
    background: #ff004d;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #ffffff;
    line-height: 14px;
  }
}
.lyric-title {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #333333;
  letter-spacing: 0px;
  margin-bottom: 18px;
}
.lyric {
  width: 234px;
  height: 622px;
  background: #f8f8f8;
  box-sizing: border-box;
  padding:20px 0px;
  overflow-y:scroll;
    font-size: 13px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #666666;
    line-height: 28px;

}
.lyric::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 4px;  /*高宽分别对应横竖滚动条的尺寸*/
  padding-right: 8px;
  }
  .lyric::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  background: #d8d8d8;
  border-radius: 3px;
  width: 4px;



  }
  .lyric::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  }
</style>
