<template>
  <div class="big">
    <div class="headertab">
      <ul>
        <li class="li-active">点评详情</li>
        <li>收藏 {{comment.favNum}}</li>
        <li>点评 {{comment.commentNum}}</li>
      </ul>
      <div class="paging" v-if='commentObj.total>5' >
        <img :class="leftfalg?'hand':''" :src="towardsLeftImg" alt="" @click="leftbut" /><span>{{commentObj.pageNum}}/{{commentObj.total|totalNum}}</span
        ><img :class="rightfalg?'hand':''" :src="towardsRightImg" alt="" @click="rightbut" />
      </div>
    </div>
    <div class="content">
         <ul v-for="item in commentObj.list" :key="item.id">
        <li><img :src="item.teacherAvatar||imgsrc" alt="" /></li>
        <li>
          <p class="title">{{item.teacherName}}</p>
          <p class="contenttitle">
            {{item.content}}
          </p>
          <p class='time'>{{ $timestampToString(item.createTime)}}</p>
        </li>
      </ul>
      <!-- commentObj.list.length==0 -->
      <div class="default" v-if="commentObj.list===undefined?true:commentObj.list.length==0" >
          <div><img src="../../../assets/default.png" alt=""></div>
          <p>暂无评论~</p>
      </div>
    </div>
  </div>
</template>

<script>
// 每页显示数量
let CURRENT_INDEX=5
import {train_commentList} from '../../../api/train'
import Left from "../../../assets/towardsLeft.png"
import LeftActive from "../../../assets/towardsLeftActive.png";
import Right from "../../../assets/towardsRight.png"
import RightActive from "../../../assets/towardsRightActive.png"
import imgsrc from '../../../assets/Headportrait.png'
let toolfn=function(val){
     let num =val/CURRENT_INDEX
         if(num===parseInt(num)){
             return num;
         }else{
             return parseInt(num)+1
         }
       }
export default {
    props:['comment'],
  data () {
    return {
      towardsLeftImg: Left,
      towardsRightImg: Right,
      pageNum:1,
      commentObj:{},
    //   判断鼠标样式是否为小手
      leftfalg:false,
      rightfalg:false,
      teacherAvatar:'',
      imgsrc
      
    }
  },
  created(){
      this.commentList()
  },
   methods: {
   
    commentList(){
        let p={id:this.$route.query.id,
               pageNum:this.pageNum,
               pageSize:CURRENT_INDEX
        }
        train_commentList(p).then(res=>{
            res.data.list.forEach(item=>{
                if(item.teacherAvatar!=undefined){
                  let index=item.teacherAvatar.indexOf('https://');
                if(index==-1) {
                    item.teacherAvatar=''
                }
                }
              
            })
            this.commentObj=res.data
            if(res.data.pageNum>1){
               this.towardsLeftImg=LeftActive
               this.leftfalg=true
            }else{
                  this.towardsLeftImg=Left
                   this.leftfalg=false
            }
           
            if(toolfn(res.data.total)!==res.data.pageNum){
                 this.towardsRightImg=RightActive
                 this.rightfalg=true

            }else{
                this.towardsRightImg=Right
                this.rightfalg=false
            }
        })
    },
    leftbut(){
        if(this.commentObj.pageNum>1){
            this.pageNum-=1;
            this.commentList();

        }
    },
    rightbut(){
        if(this.commentObj.pageNum<toolfn(this.commentObj.total)){
              this.pageNum+=1;
            this.commentList();
        }
    }
   },
   filters: {
    totalNum(val){
       return toolfn(val)
    }
   },
  watch: {
      'comment'(newV){
          this.comment=newV
      }
  }
}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.big {
  margin-top: 30px;
  .headertab {
    display: flex;
    justify-content: space-between;
    margin-bottom: 33px;
    > ul {
      display: flex;
      align-items: flex-end;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #666666;
      li {
        margin-right: 20px;
      }
      .li-active {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }
    }
    > .paging {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      line-height: 16px;
      span {
        margin: 0px 9px;
        vertical-align: middle;
      }
      img {
        width: 12px;
        height: 12px;
        vertical-align: middle;
      }
      .hand{
          cursor: pointer;
      }
    }
  }
  .content {
      .default{
          padding-top: 126px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
              border-radius: 0px;
              width: 112px;
              height:105px;
          }
          p{  
                margin-top:16px;
                height: 14px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: LEFT;
                color: #222222;
                line-height: 14px;
          }
          .button{
            width: 160px;
            height: 50px;
            background: #ff004d;
            cursor: pointer;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 50px;
          }

      }
      min-height: 300px;
      >ul{
           display:flex;
           box-sizing: border-box;
           padding-bottom: 30px;
          border-bottom: 1px solid rgba($color:#dcdfe6, $alpha: 0.4);
           margin-bottom:40px;
           >li:nth-child(1){
               margin-right:18px;
           }
      }
    .contenttitle {
      font-size: 13px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      margin:14px 0px;
    }
    .title{
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #666666;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .time{
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #999999;
    }
  }
}
</style>