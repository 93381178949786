// 培训api
import { $post } from './index'

export const train_taskList = (params) => {
  return $post('/api/train/taskList', params)
}

export const train_submitTask = (params) => {
  return $post('/api/train/submitTask', params)
}
export const train_taskDetail = (params) => {
  return $post('/api/train/taskDetail?taskId=' + params)
}
//已交作业列表
export const train_taskSongList = (params) => {
  return $post('/api/train/taskSongList?taskId=' + params)
}
// 提交作品详情

export const train_taskSongDetail = (params) => {
  return $post('/api/train/taskSongDetail?songId=' + params)
}
// 作品点评
export const train_commentList = (params) => {
    return $post('/api/train/commentList',params)
}
//提交作业详情
export const train_submitVideoTask = (params)=>{
  return $post('/api/train/submitVideoTask',params)
}
